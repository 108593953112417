
$(document).ready(function(){
  var allPanels = $('.accordion-content .accordion-item-content').hide();
    
  $('.accordion-content .accordion-item-title > a').click(function() {
    if($(this).parent().hasClass('expand')) {
      $(this).parent().removeClass('expand');
      $(this).parent().next().removeClass('expand-content').slideUp();
    } else {
      $('.accordion-link').parent().removeClass('expand');
      allPanels.removeClass('expand-content').slideUp();
      $(this).parent().addClass('expand');
      $(this).parent().next().addClass('expand-content').slideDown();
    }
    return false;
  });
});