$(document).ready(function(){
  var $els = $('.line-and-text'); // Note this must be moved to within event handler if dynamically adding elements - I've placed it for performance reasons 
  var $window = $(window);

  $els.each(function(){ // Iterate over all skillbars
    var $this = $(this); 
    if($window.scrollTop() > $this.offset().top - $window.height()){ // Check if it's in viewport
      startCounter($this);
    }
  });
  
  $window.on('scroll', function(){
    $els.each(function(){ // Iterate over all skillbars
      var $this = $(this); 
      if($window.scrollTop() > $this.offset().top - $window.height()){ // Check if it's in viewport
        startCounter($this);
      }
    });
  });
});

function startCounter(obj){
  var $this = obj;
	$this.find('.counter').each(function (index) {
      var size = $(this).attr('data-count').split(".")[1] 
                  ? $(this).attr('data-count').split(".")[1].length 
                  : 0;
      if(!$(this).hasClass('counter-end')) {
        $(this).prop('Counter',0).animate({
          Counter: $(this).text()
        }, {
          duration: 2000,
          easing: 'swing',
          step: function (now) {
            $(this).text(parseFloat(now).toFixed(size));
          }
        });
      }

      $(this).addClass('counter-end')
	});
};