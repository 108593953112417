$(document).ready(function () {
  if($('#ir-calendar').length) {
    function selectDate(date) {
      $('#ir-calendar').updateCalendarOptions({
        date: date
      });
      // console.log(calendar.getSelectedDate());
    }

    var arrowBtn = '<svg xmlns="http://www.w3.org/2000/svg" width="10.787" height="7" viewBox="0 0 10.787 7"> <defs> <clipPath id="8y64gio09a"> <path fill="none" d="M0 0H10.787V7H0z"/> </clipPath> </defs> <g clip-path="url(#8y64gio09a)"> <path fill="#00becf" d="M7.393 9L2 3.607 3.607 2l3.786 3.787L11.18 2l1.607 1.607z" transform="translate(-2 -2)"/> </g> </svg>'

    var defaultConfig = {
      weekDayLength: 1,
      // date: '08-05-2021',
      onClickDate: selectDate,
      weekDayLength: 2,
      prevButton: arrowBtn,
      nextButton: arrowBtn,
      showYearDropdown: true,
      startOnMonday: false,
      showTodayButton: false
    };

    var calendar = $('#ir-calendar').calendar(defaultConfig);
    // console.log(calendar.getSelectedDate());
  }
});