$('.btn-show-more').on({
  click: function (e) {
     $(this).toggleClass('expand');

     if($(this).hasClass('expand')) {
       $(this).find('.btn-show-more-title').text('Show Less')
     } else {
       $(this).find('.btn-show-more-title').text('Show More')
     }
  }
});