
$(document).ready(function () {
   const menu = $('.navbar-menu__wrapper');
   const menuButton = $('.navbar-menu-icon');
   const overlay = $('.overlay');
   const menuChild = $('.navbar-link-with-child');
   const menuChildContent = $('.navbar-menu__sub-menu-content');
   const menuBack = $('.navbar-menu-back');
   const navSearch = $('.navbar-search');
   const navSearchWrapper = $('.navbar-search__wrapper');
   const navLang = $('.navbar-language-button');
   const navLangWrapper = $('.navbar-language__wrapper');

   function hideMenu(btn) {
      btn.addClass('open-menu').removeClass('close-menu');
      menuChild.parent().removeClass('active');
      menuChildContent.addClass('slide-out-child').removeClass('slide-in-child opened active')
      if(menu.hasClass('opened')) {
         menu.removeClass('slide-in opened').addClass('slide-out');
      }
      overlay.removeClass('d-block');
      $('body').removeClass('overflow-hidden');
   }

   function showMenu(btn) {
      btn.removeClass('open-menu').addClass('close-menu');
      $('body').addClass('overflow-hidden');
      overlay.addClass('d-block');
      menu.addClass('slide-in opened').removeClass('slide-out');
   }

   function hideSearch() {
      navSearch.removeClass('hide-search');
      navSearchWrapper.removeClass('opened');
      overlay.removeClass('d-block');
      $('body').removeClass('overflow-hidden');
   }

   function hideLanguage() {
      navLang.removeClass('hide-lang');
      navLangWrapper.removeClass('opened');
      overlay.removeClass('d-block');
      $('body').removeClass('overflow-hidden');
   }

   menuButton.on({
      click: function (e) {
         hideSearch();
         hideLanguage();
   
         if($(this).hasClass('close-menu')) {
            hideMenu($(this));
         } else {
            showMenu($(this));
         }
         e.stopPropagation();
      }
   });

   menuChild.on({
      click: function (e) {
         $(this)
            .parent()
            .addClass('active')
            .siblings()
            .removeClass('active')

         menuChildContent.removeClass('slide-out-child');
         
         if(!menuChildContent.hasClass('active')) {
            menuChildContent.addClass('slide-in-child opened active');
         }

         var thisID = $(this).attr("data-menu");
         $('.navbar-menu__sub-menu-wrapper').removeClass('opened')
         $(thisID).addClass('opened');

         e.stopPropagation();
      }
   });

   menuBack.on({
      click: function (e) {
         menuChild.removeClass('active')
         menuChildContent.addClass('slide-out-child').removeClass('slide-in-child active');
         e.stopPropagation();
      }
   });

   navSearch.on({
      click: function (e) {
         hideMenu(menuButton);
         hideLanguage();
         $(this).toggleClass('hide-search');
   
         if($(this).hasClass('hide-search')) {
            $('body').addClass('overflow-hidden');
            navSearchWrapper.addClass('opened');
            overlay.addClass('d-block');
         } else {
            hideSearch();
         }
   
         e.stopPropagation();
      }
   });

   navLang.on({
      click: function (e) {
         hideMenu(menuButton);
         hideSearch();
         $(this).toggleClass('hide-lang');
   
         if($(this).hasClass('hide-lang')) {
            $('body').addClass('overflow-hidden');
            navLangWrapper.addClass('opened');
            overlay.addClass('d-block');
         } else {
            hideLanguage();
         }
   
         e.stopPropagation();
      }
   });

   $(document).on("click", ".navbar-menu__wrapper, .navbar-search__wrapper, .navbar-language__wrapper", function(e) {
      e.stopPropagation();
   });
   
   $(document).on('click', function(){
      hideMenu(menuButton);
      hideSearch();   
      hideLanguage();
      $('body').removeClass('overflow-hidden');
   });
 
 });